import _export from "../internals/export";
import _global3 from "../internals/global";
import _functionUncurryThis from "../internals/function-uncurry-this";
import _toIntegerOrInfinity from "../internals/to-integer-or-infinity";
import _thisNumberValue from "../internals/this-number-value";
import _stringRepeat from "../internals/string-repeat";
import _fails from "../internals/fails";
var $ = _export;
var _global2 = _global3;
var uncurryThis = _functionUncurryThis;
var toIntegerOrInfinity = _toIntegerOrInfinity;
var thisNumberValue = _thisNumberValue;
var $repeat = _stringRepeat;
var fails = _fails;
var RangeError = _global2.RangeError;
var String = _global2.String;
var floor = Math.floor;
var repeat = uncurryThis($repeat);
var stringSlice = uncurryThis("".slice);
var un$ToFixed = uncurryThis(1 .toFixed);

var pow = function (x, n, acc) {
  return n === 0 ? acc : n % 2 === 1 ? pow(x, n - 1, acc * x) : pow(x * x, n / 2, acc);
};

var log = function (x) {
  var n = 0;
  var x2 = x;

  while (x2 >= 4096) {
    n += 12;
    x2 /= 4096;
  }

  while (x2 >= 2) {
    n += 1;
    x2 /= 2;
  }

  return n;
};

var multiply = function (data, n, c) {
  var index = -1;
  var c2 = c;

  while (++index < 6) {
    c2 += n * data[index];
    data[index] = c2 % 10000000;
    c2 = floor(c2 / 10000000);
  }
};

var divide = function (data, n) {
  var index = 6;
  var c = 0;

  while (--index >= 0) {
    c += data[index];
    data[index] = floor(c / n);
    c = c % n * 10000000;
  }
};

var dataToString = function (data) {
  var index = 6;
  var s = "";

  while (--index >= 0) {
    if (s !== "" || index === 0 || data[index] !== 0) {
      var t = String(data[index]);
      s = s === "" ? t : s + repeat("0", 7 - t.length) + t;
    }
  }

  return s;
};

var FORCED = fails(function () {
  return un$ToFixed(0.00008, 3) !== "0.000" || un$ToFixed(0.9, 0) !== "1" || un$ToFixed(1.255, 2) !== "1.25" || un$ToFixed(1000000000000000100, 0) !== "1000000000000000128";
}) || !fails(function () {
  // V8 ~ Android 4.3-
  un$ToFixed({});
}); // `Number.prototype.toFixed` method
// https://tc39.es/ecma262/#sec-number.prototype.tofixed

$({
  target: "Number",
  proto: true,
  forced: FORCED
}, {
  toFixed: function toFixed(fractionDigits) {
    var number = thisNumberValue(this);
    var fractDigits = toIntegerOrInfinity(fractionDigits);
    var data = [0, 0, 0, 0, 0, 0];
    var sign = "";
    var result = "0";
    var e, z, j, k; // TODO: ES2018 increased the maximum number of fraction digits to 100, need to improve the implementation

    if (fractDigits < 0 || fractDigits > 20) throw RangeError("Incorrect fraction digits"); // eslint-disable-next-line no-self-compare -- NaN check

    if (number != number) return "NaN";
    if (number <= -1e+21 || number >= 1e+21) return String(number);

    if (number < 0) {
      sign = "-";
      number = -number;
    }

    if (number > 1e-21) {
      e = log(number * pow(2, 69, 1)) - 69;
      z = e < 0 ? number * pow(2, -e, 1) : number / pow(2, e, 1);
      z *= 4503599627370496;
      e = 52 - e;

      if (e > 0) {
        multiply(data, 0, z);
        j = fractDigits;

        while (j >= 7) {
          multiply(data, 10000000, 0);
          j -= 7;
        }

        multiply(data, pow(10, j, 1), 0);
        j = e - 1;

        while (j >= 23) {
          divide(data, 1 << 23);
          j -= 23;
        }

        divide(data, 1 << j);
        multiply(data, 1, 1);
        divide(data, 2);
        result = dataToString(data);
      } else {
        multiply(data, 0, z);
        multiply(data, 1 << -e, 0);
        result = dataToString(data) + repeat("0", fractDigits);
      }
    }

    if (fractDigits > 0) {
      k = result.length;
      result = sign + (k <= fractDigits ? "0." + repeat("0", fractDigits - k) + result : stringSlice(result, 0, k - fractDigits) + "." + stringSlice(result, k - fractDigits));
    } else {
      result = sign + result;
    }

    return result;
  }
});
export default {};